import React from 'react'
// import { Link, graphql } from 'gatsby'
import classNames from 'classnames'
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Layout from 'layout/layout'
import style from 'assets/jss/styles/pages/mainPageStyle.js'
import { makeStyles } from '@material-ui/core/styles'
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import { RightSection, LeftSection } from 'pages/reformas/reformas-banos-en-barcelona';

const useStyles = makeStyles(style)

const ReformasPisos = ({ data }) => {
  const classes = useStyles()
  return (
    <Layout
      pageTitle="Reformas pisos en Barcelona de viviendas."
      pageDescription="Reformas integrales de pisos, baños y cocinas en Barcelona. Pídenos presupuesto gratis y sin compromiso, te asesoramos de las distintas calidades y técnicas para que elijas lo más adecuado para ti. Equipo de albañiles, fontaneros y electricistas con gran experiencia."
      headerImageGatsby={data.reformasPisosJson.headerImage.childImageSharp.fluid}
      filter="dark"
      content={<span>
        <h1 className={classes.title}>{data.reformasPisosJson.title}</h1>
        <h2 className={classes.subtitle}>{data.reformasPisosJson.subtitle}</h2>
        </span>
      }
    >
      <div dangerouslySetInnerHTML={{__html: data.reformasPisosJson.content.childMarkdownRemark.html}} />

      {data.reformasPisosJson.gallery.map((obj, i) => {
        if (i % 2 == 0) {
          return (
            <LeftSection
              key={i}
              classes={classes} 
              image={obj.image.childImageSharp.fluid}
              alt={obj.alt}
              text={obj.text}
              title={obj.title}
            />
          )
        } else return (
          <RightSection 
            key={i}
            classes={classes} 
            image={obj.image.childImageSharp.fluid}
            alt={obj.alt}
            text={obj.text}
            title={obj.title}
          />
        )
      })}
          
    </Layout>
  )
}

export default ReformasPisos

export const query = graphql`
  query ReformasPisospageQuery {
    reformasPisosJson {
      title
      subtitle
      headerImage {
        childImageSharp {
          fluid(maxHeight: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        title
        text
        alt
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;